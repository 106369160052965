import React from "react";
import "../styles/headContent.css";
import botImage from "../assets/chatbot.png"; // Coloque a imagem na pasta 'src/assets'

function HeadContent() {
  return (
    <section className="head">
      <div className="headWrapper">
        <div className="content">
          <h1>Assistente Mazoto</h1>
          <p>
            O assistente que te ajudará com sua rotina em seu estabelecimento e
            a atender seus clientes da melhor forma.
          </p>
          <button className="button">Quero no meu estabelecimento</button>
        </div>
        <div className="image-container">
          <img src={botImage} alt="Assistente Mazoto" className="img" />
        </div>
      </div>
    </section>
  );
}

export default HeadContent;
