import React from "react";
import "../styles/card.css";
import { Card, CardContent, Typography } from "@mui/material";

export default function CardAbout(props) {
  return (
    <Card className="card" sx={{ minWidth: 275 }}>
      <CardContent >
        <Typography className="text" variant="h6" sx={{ marginBottom: 3, fontWeight: "bold" }}>{props.title}</Typography>
        <Typography className="text" variant="subtitle1">{props.bodyText}</Typography>
      </CardContent>
    </Card>
  );
}
