import "./App.css";
import Navbar from "./components/navbar";
import HeadContent from "./components/headContent";
import AboutAssistantMazoto from "./components/aboutAssistantMazoto";

function App() {
  return (
    <div className="main">
        <Navbar/>
        <HeadContent/>
        <AboutAssistantMazoto/>
    </div>
  );
}

export default App;
