import "../styles/navbar.css";
import logo from "../assets/mazoto.png";
import React from "react";

function Navbar() {
  return (
    <header className="header">
      <div className="headerWrapper">
      <img src={logo} className="logo" alt="logo"/>
      <nav className="nav">
        <a
          href="https://reactjs.org"
          className="link"
          target="_blank"
          rel="noopener noreferrer"
        >
          O que é o Assistente Mazoto
        </a>
        <a
          href="https://reactjs.org"
          className="link"
          target="_blank"
          rel="noopener noreferrer"
        >
          Quem Somos
        </a>
        <a
          href="https://reactjs.org"
          className="link"
          style={{ color: "#158CD8" }}
        >
          Contate-nos
        </a>
      </nav>
      </div>
    </header>
  );
}

export default Navbar;
