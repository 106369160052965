import "../styles/aboutAssistantMazoto.css";
import React from "react";
import CardAbout from "./card";

function AboutAssistantMazoto() {
  return (
    <section className="main">
      <div className="wrapper">
        <div className="headText">
          <h1>O que é o Assistente Mazoto</h1>
          <p>
            O nosso assistente virtual está aqui para lhe auxiliar a gerenciar
            seu estabelecimento e ajudar a atender seus clientes realizando
            tarefas como:
          </p>
          <div className="cardsList">
            <CardAbout
              title="Agendando horários"
              bodyText="Consulta sua agenda e verifica se estará disponível para o horário que o cliente gostaria de marcar"
            />
            <CardAbout
              title="Agendando horários"
              bodyText="Consulta sua agenda e verifica se estará disponível para o horário que o cliente gostaria de marcar"
            />
            <CardAbout
              title="Agendando horários"
              bodyText="Consulta sua agenda e verifica se estará disponível para o horário que o cliente gostaria de marcar"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutAssistantMazoto;
